/**
 *  !! COMPATIBILITY !!
 *  OLD IOS BROWSERS DON'T SUPPORT ES6 MODULES LIKE MEDIA QUERIES COMPILED BY SASS
 *
 * !! SOLUTION !!
 *  REPLACE THE COMPILED CSS WITH @media only screen and (max-width: 800px) {
 */

import "./css/style.scss";
import "./css/font.scss";
import "./css/front.scss";
import "./css/loader.scss";
import App from "./js/App.js";

window.onload = () => {
  new App();
};
