import Config from "./Config.js";
import Utils from "./Utils.js";
import EventEmitter from "@onemorestudio/eventemitterjs";
export default class PageContent extends EventEmitter {
  constructor(container, id) {
    super();
    this.container = container;
    this.id = id;
    this.PATH = Config.PATH;
    this.IMAGES_PATH = Config.IMAGES_PATH;
    this.initContent();
  }

  set _id(id) {
    this.id = id;
    this.initContent();
  }

  async initContent() {
    this.loader = document.querySelector(".loader");
    this.mainColumn = document.querySelector(".mainColumn");
    this.mainColumn.innerHTML = "";
    const data = await Utils.loadJson(
      `${this.PATH}listImagesForTag.php?tag_id=${this.id}`
    );
    // console.log(data);
    if (data && !data.error) {
      // data.forEach(async (media) => {
      // console.log("start loading stuff ");
      this.mainColumn.classList.add("hidden");
      this.loader.classList.remove("hidden");
      await this.loadAllImagesInPage(data, 10);
      console.log("end of loading stuff");
      this.mainColumn.classList.remove("hidden");
      this.loader.classList.add("hidden");
      if (data.length > 10)
        await this.loadAllImagesInPage(data, data.length - 10, 10);
    }
    //   for (let i = 0; i < data.length; i++) {
    //     const media = data[i];
    //     const div = document.createElement("div");
    //     div.classList.add("horizontal_viewer");
    //     div.dataset.order_id = media.order_id;
    //     this.mainColumn.appendChild(div);

    //     // add handle
    //     // const handle = document.createElement("div");
    //     // handle.classList.add("material-icons", "handle");
    //     // handle.textContent = "open_with";
    //     // div.appendChild(handle);

    //     // check if it's a image/video OR a text
    //     if (parseInt(media.type) === 2) {
    //       div.classList.add("text");
    //       const wrapper = document.createElement("div");
    //       wrapper.classList.add("text-wrapper");
    //       div.appendChild(wrapper);
    //       if (parseInt(media.text_size) == 1) {
    //         div.classList.add("regularText");
    //         // if (media.text.indexOf("ql-align-right") != -1)
    //         //   div.classList.add("right");
    //         switch (parseInt(media.text_columns)) {
    //           case 1:
    //             wrapper.classList.add("singleColumn");
    //             break;
    //           case 2:
    //             wrapper.classList.add("doubleColumn");
    //             break;
    //           case 3:
    //             wrapper.classList.add("tripleColumn");
    //             break;
    //         }
    //         switch (parseInt(media.text_position)) {
    //           case 1:
    //             wrapper.classList.add("left");
    //             break;
    //           case 2:
    //             wrapper.classList.add("center");
    //             break;
    //           case 3:
    //             wrapper.classList.add("right");
    //             break;
    //         }
    //       }
    //       wrapper.innerHTML = media.text;

    //       // div.querySelector("p").style.columnCount = media.text_columns;

    //       // if (media.text_columns > 1) div.classList.add("fullwidth");

    //       //       console.log("text");
    //       //       // add text editor
    //       //       const editor = document.createElement("div");
    //       //       editor.id = `text-editor_${media.order_id}`;
    //       //       editor.classList.add("text-editor");
    //       //       editor.dataset.order_id = media.order_id;
    //       //       editor.innerHTML += `<!-- Create the toolbar container -->
    //       //   <div id="toolbar_${media.order_id}">
    //       //     <button class="ql-bold">Bold</button>
    //       //     <button class="ql-italic">Italic</button>
    //       //     <button class="ql-link">Link</button>

    //       //   </div>

    //       //   <!-- Create the editor container -->
    //       //   <div id="quillEditor_${media.order_id}">
    //       //   ${media.text}
    //       //   </div>
    //       //   <div class="buttons">
    //       //   <button class="saveText" data-media_id="${
    //       //     media.media_id
    //       //   }" id="update_button_${media.media_id}">Update</button>
    //       //   <button class="material-icons delete" id="delete_button_${
    //       //     media.media_id
    //       //   }">delete</button>

    //       //   <!-- 2 options for text size -->
    //       //   <ul id="text_type_${media.order_id}">
    //       //   <li>
    //       //   <input type="radio" id="quote" name="textSize" value="0" ${
    //       //     parseInt(media.text_size) == 0 ? "checked" : ""
    //       //   } data-order_id="${media.order_id}">
    //       //   <label for="quote">Quote</label>
    //       //   </li>
    //       //   <li>
    //       //   <input type="radio" id="text" name="textSize" value="1" ${
    //       //     parseInt(media.text_size) == 1 ? "checked" : ""
    //       //   } data-order_id="${media.order_id}">
    //       //   <label for="text">Text standard</label>
    //       //   </li>
    //       //   </ul>
    //       //   </div>`;
    //       //       div.appendChild(editor);
    //       //       //   this.quillEditor = new Quill(`#quillEditor_${media.order_id}`, {
    //       //       //     modules: {
    //       //       //       toolbar: `#toolbar_${media.order_id}`,
    //       //       //     },
    //       //       //     theme: "snow",
    //       //       //   });
    //       //       //   document
    //       //       //     .querySelector(`#update_button_${media.media_id}`)
    //       //       //     .addEventListener("click", this.onUpdateText.bind(this));
    //       //       //   document
    //       //       //     .querySelector(`#delete_button_${media.media_id}`)
    //       //       //     .addEventListener("click", this.onDeleteButtonClick.bind(this));
    //       //       //   document
    //       //       //     .querySelector(`#text_type_${media.order_id}`)
    //       //       //     .addEventListener("change", this.onTextTypeChange.bind(this));
    //     } else {
    //       //add organizer
    //       const organizer = document.createElement("div");
    //       organizer.classList.add("organizer");
    //       div.appendChild(organizer);

    //       const size = await Utils.getImageSize(
    //         `${this.IMAGES_PATH}${media.fullsize}`
    //       );
    //       const aspect_ratio = size.w / size.h;
    //       // this.domElement.style.aspectRatio = aspect_ratio;
    //       let max = 3;
    //       let _class = "";
    //       switch (parseInt(media.size)) {
    //         case 1:
    //           max = 2;
    //           _class = "doubleColumn";
    //           break;
    //         case 2:
    //           max = 1;
    //           _class = "tripleColumn";
    //           break;
    //       }

    //       for (let i = 0; i < max; i++) {
    //         const image_div = document.createElement("div");
    //         image_div.classList.add("image");
    //         // ATTENTION à changer si on veut changer l'alignement

    //         if (i == parseInt(media.position)) {
    //           image_div.classList.add("hero");
    //           image_div.dataset.order_id = media.order_id;
    //           if (_class != "") image_div.classList.add(_class);

    //           //create ratio div
    //           const ratio_div = document.createElement("div");
    //           ratio_div.classList.add("ratioBox");
    //           ratio_div.style.backgroundImage = `url(${this.IMAGES_PATH}${media.fullsize})`;
    //           image_div.appendChild(ratio_div);
    //           // add image-ratio style to image_div calculated from image ratio
    //           image_div.style.aspectRatio = aspect_ratio;

    //           // if video
    //           if (media.type == 1) {
    //             console.log("setup for video");
    //             ratio_div.style.backgroundImage = "unset";
    //             // add video element
    //             this.video = document.createElement("video");
    //             this.video.src = `${Config.IMAGES_PATH}${media.video}`;
    //             this.video.loop = true;
    //             this.video.autoplay = true;
    //             this.video.muted = true;
    //             this.video.controls = false;
    //             this.video.playsinline = true;
    //             this.video.setAttribute("playsinline", "");
    //             ratio_div.appendChild(this.video);
    //             const ratio = await this.calculateAspectRatioForVideo(
    //               this.video.src
    //             );
    //             // this.aspectRatio = aspect_ratio;
    //             // this.domElement.style.aspectRatio = aspect_ratio;
    //             image_div.style.aspectRatio = ratio;
    //           } else {
    //             // add a download button
    //             const downloadButton = document.createElement("a");
    //             downloadButton.classList.add(
    //               "material-icons",
    //               "download-button"
    //             );
    //             downloadButton.dataset.url = `${this.IMAGES_PATH}${media.fullsize}`;
    //             downloadButton.dataset.filename = `${media.fullsize.replace(
    //               "images/small/",
    //               "Erwan_Bouroullec_"
    //             )}`;
    //             downloadButton.textContent = "download";
    //             // add it to the image_div
    //             ratio_div.appendChild(downloadButton);
    //             downloadButton.addEventListener(
    //               "click",
    //               this.onDownload.bind(this)
    //             );
    //           }

    //           //   // add overlay
    //           //   const overlay = document.createElement("div");
    //           //   overlay.classList.add("overlay");
    //           //   image_div.appendChild(overlay);
    //           //   // add the 3 span buttons in the overlay
    //           //   for (let i = 0; i < 3; i++) {
    //           //     let span = document.createElement("span");
    //           //     span.classList.add("size-button");
    //           //     span.dataset.value = i;
    //           //     span.dataset.tag_id = this.id;
    //           //     span.textContent = i + 1;
    //           //     if (i === parseInt(media.size)) {
    //           //       span.classList.add("selected");
    //           //     }
    //           //     // span.addEventListener(
    //           //     //   "click",
    //           //     //   this.onSizeButtonClick.bind(this)
    //           //     // );
    //           //     overlay.appendChild(span);
    //           //   }
    //           //   // create an edit button
    //           //   let editButton = document.createElement("button");
    //           //   editButton.classList.add("material-icons", "edit-button");
    //           //   editButton.textContent = "edit";
    //           //   // editButton.dataset.media_id = this.mediaId;
    //           //   overlay.appendChild(editButton);
    //           //   //   editButton.addEventListener(
    //           //   //     "click",
    //           //   //     this.onEditButtonClick.bind(this)
    //           //   //   );

    //           //   //create a delete button
    //           //   const deleteButton = document.createElement("button");
    //           //   deleteButton.classList.add("material-icons", "delete-button");
    //           //   deleteButton.textContent = "delete";
    //           //   overlay.appendChild(deleteButton);
    //           //   //   deleteButton.addEventListener(
    //           //   //     "click",
    //           //   //     this.onDeleteButtonClick.bind(this)
    //           //   //   );

    //           //   // add tags
    //           //   // const ul = document.createElement("ul");
    //           //   // ul.classList.add("tags");
    //           //   // const demo = ["tag1", "tag2", "tag3"];
    //           //   // demo.forEach((tag) => {
    //           //   //   const li = document.createElement("li");
    //           //   //   li.textContent = tag;
    //           //   //   ul.appendChild(li);
    //           //   // });
    //           //   // image_div.appendChild(ul);
    //           //   // get all tags from database
    //           //   // and build a select list
    //           const tags = await Utils.loadJson(`${Config.PATH}listTags.php`);
    //           //   const select = document.createElement("select");
    //           //   select.classList.add("tags");
    //           //   select.dataset.order_id = media.order_id;
    //           //   const option = document.createElement("option");
    //           //   option.textContent = "select tag";
    //           //   select.appendChild(option);
    //           //   tags.forEach((tag) => {
    //           //     const option = document.createElement("option");
    //           //     option.value = tag.tag_id;
    //           //     option.textContent = tag.tag_value;
    //           //     if (tag.tag_id == media.related_tag) {
    //           //       option.selected = true;
    //           //     }
    //           //     select.appendChild(option);
    //           //   });
    //           //   const selectWrapper = document.createElement("div");
    //           //   selectWrapper.classList.add("select-wrapper");
    //           //   selectWrapper.appendChild(select);
    //           //   image_div.appendChild(selectWrapper);

    //           // get all tags from the menu
    //           const menuElementText = document.querySelectorAll(
    //             "#side_menu .list li"
    //           );
    //           // create an array with all textContent of menuElementText
    //           const menuElementTextArray = [];
    //           menuElementText.forEach((element) => {
    //             menuElementTextArray.push(element.textContent.toLowerCase());
    //           });
    //           //   add single tag
    //           const ul = document.createElement("ul");
    //           const li = document.createElement("li");
    //           tags.forEach((tag) => {
    //             if (tag.tag_id == media.related_tag) {
    //               li.textContent = tag.tag_value;
    //               li.dataset.tag_id = tag.tag_id;
    //               if (
    //                 menuElementTextArray.includes(tag.tag_value.toLowerCase())
    //               ) {
    //                 li.classList.add("isHomepage");
    //               }
    //             }
    //           });
    //           ul.appendChild(li);
    //           image_div.appendChild(ul);

    //           ul.addEventListener("click", this.tagClick.bind(this));
    //         }
    //         organizer.appendChild(image_div);
    //       }
    //     }
    //   }
    // console.log("end of loading stuff");
  }

  loadAllImagesInPage(data, limit = null, offset = 0) {
    return new Promise(async (resolve, reject) => {
      console.log("start loading stuff ", data);
      if (limit) {
        const range =
          offset + limit > data.length ? data.length : offset + limit;
        data = data.slice(offset, range);
      }

      for (let i = 0; i < data.length; i++) {
        const media = data[i];
        const div = document.createElement("div");
        div.classList.add("horizontal_viewer");
        div.dataset.order_id = media.order_id;
        if (this.id != media.tag_id) {
          console.log("ERROR WHILE LOADING ", this.id, media.tag_id);
          return;
        } else console.log("ALL GOOD WITH ", this.id, media.tag_id);
        this.mainColumn.appendChild(div);

        // add handle
        // const handle = document.createElement("div");
        // handle.classList.add("material-icons", "handle");
        // handle.textContent = "open_with";
        // div.appendChild(handle);

        // check if it's a image/video OR a text
        if (parseInt(media.type) === 2) {
          div.classList.add("text");
          const wrapper = document.createElement("div");
          wrapper.classList.add("text-wrapper");
          div.appendChild(wrapper);

          /**
           * REMOVE TEXT SIZE
           * ADD QUOTE CLASS IF TEXT_SIZE == 0
           */
          // if (parseInt(media.text_size) == 1) {
          div.classList.add("regularText");
          // if (media.text.indexOf("ql-align-right") != -1)
          //   div.classList.add("right");
          switch (parseInt(media.text_columns)) {
            case 1:
              wrapper.classList.add("singleColumn");
              break;
            case 2:
              wrapper.classList.add("doubleColumn");
              break;
            case 3:
              wrapper.classList.add("tripleColumn");
              break;
          }
          switch (parseInt(media.text_position)) {
            case 1:
              wrapper.classList.add("left");
              break;
            case 2:
              wrapper.classList.add("center");
              break;
            case 3:
              wrapper.classList.add("right");
              break;
          }
          // }
          if (parseInt(media.text_size) == 0) {
            div.classList.add("quote");
          }
          wrapper.innerHTML = media.text;

          // div.querySelector("p").style.columnCount = media.text_columns;

          // if (media.text_columns > 1) div.classList.add("fullwidth");

          //       console.log("text");
          //       // add text editor
          //       const editor = document.createElement("div");
          //       editor.id = `text-editor_${media.order_id}`;
          //       editor.classList.add("text-editor");
          //       editor.dataset.order_id = media.order_id;
          //       editor.innerHTML += `<!-- Create the toolbar container -->
          //   <div id="toolbar_${media.order_id}">
          //     <button class="ql-bold">Bold</button>
          //     <button class="ql-italic">Italic</button>
          //     <button class="ql-link">Link</button>

          //   </div>

          //   <!-- Create the editor container -->
          //   <div id="quillEditor_${media.order_id}">
          //   ${media.text}
          //   </div>
          //   <div class="buttons">
          //   <button class="saveText" data-media_id="${
          //     media.media_id
          //   }" id="update_button_${media.media_id}">Update</button>
          //   <button class="material-icons delete" id="delete_button_${
          //     media.media_id
          //   }">delete</button>

          //   <!-- 2 options for text size -->
          //   <ul id="text_type_${media.order_id}">
          //   <li>
          //   <input type="radio" id="quote" name="textSize" value="0" ${
          //     parseInt(media.text_size) == 0 ? "checked" : ""
          //   } data-order_id="${media.order_id}">
          //   <label for="quote">Quote</label>
          //   </li>
          //   <li>
          //   <input type="radio" id="text" name="textSize" value="1" ${
          //     parseInt(media.text_size) == 1 ? "checked" : ""
          //   } data-order_id="${media.order_id}">
          //   <label for="text">Text standard</label>
          //   </li>
          //   </ul>
          //   </div>`;
          //       div.appendChild(editor);
          //       //   this.quillEditor = new Quill(`#quillEditor_${media.order_id}`, {
          //       //     modules: {
          //       //       toolbar: `#toolbar_${media.order_id}`,
          //       //     },
          //       //     theme: "snow",
          //       //   });
          //       //   document
          //       //     .querySelector(`#update_button_${media.media_id}`)
          //       //     .addEventListener("click", this.onUpdateText.bind(this));
          //       //   document
          //       //     .querySelector(`#delete_button_${media.media_id}`)
          //       //     .addEventListener("click", this.onDeleteButtonClick.bind(this));
          //       //   document
          //       //     .querySelector(`#text_type_${media.order_id}`)
          //       //     .addEventListener("change", this.onTextTypeChange.bind(this));
        } else {
          //add organizer
          const organizer = document.createElement("div");
          organizer.classList.add("organizer");
          div.appendChild(organizer);

          const size = await Utils.getImageSize(
            `${this.IMAGES_PATH}${media.thumb}`
          );
          const aspect_ratio = size.w / size.h;
          // this.domElement.style.aspectRatio = aspect_ratio;
          let max = 3;
          let _class = "";
          switch (parseInt(media.size)) {
            case 1:
              max = 2;
              _class = "doubleColumn";
              break;
            case 2:
              max = 1;
              _class = "tripleColumn";
              break;
          }

          for (let i = 0; i < max; i++) {
            const image_div = document.createElement("div");
            image_div.classList.add("image");
            // ATTENTION à changer si on veut changer l'alignement

            if (i == parseInt(media.position)) {
              image_div.classList.add("hero");
              image_div.dataset.order_id = media.order_id;
              if (_class != "") image_div.classList.add(_class);

              //create ratio div
              const ratio_div = document.createElement("div");
              ratio_div.classList.add("ratioBox");
              ratio_div.style.backgroundImage = `url(${this.IMAGES_PATH}${media.fullsize})`;
              // ratio_div.style.backgroundImage = `url(${size.img.src})`;
              image_div.appendChild(ratio_div);
              // add image-ratio style to image_div calculated from image ratio
              image_div.style.aspectRatio = aspect_ratio;

              // if video
              if (media.type == 1) {
                // console.log("setup for video");
                ratio_div.style.backgroundImage = "unset";
                // add video element
                this.video = document.createElement("video");
                this.video.src = `${Config.IMAGES_PATH}${media.video}`;
                this.video.loop = true;
                this.video.autoplay = true;
                this.video.muted = true;
                this.video.controls = false;
                this.video.playsinline = true;
                this.video.setAttribute("playsinline", "");
                ratio_div.appendChild(this.video);
                const ratio = await this.calculateAspectRatioForVideo(
                  this.video.src
                );
                // this.aspectRatio = aspect_ratio;
                // this.domElement.style.aspectRatio = aspect_ratio;
                image_div.style.aspectRatio = ratio;
              } else {
                // add a download button
                const downloadButton = document.createElement("a");
                downloadButton.classList.add(
                  "material-icons",
                  "download-button"
                );
                downloadButton.dataset.url = `${this.IMAGES_PATH}${media.fullsize}`;
                downloadButton.dataset.realname = `${media.original_name}`;
                downloadButton.dataset.filename = `${media.fullsize.replace(
                  "images/small/",
                  "Erwan_Bouroullec_"
                )}`;
                downloadButton.textContent = "download";
                // add it to the image_div
                ratio_div.appendChild(downloadButton);
                downloadButton.addEventListener(
                  "click",
                  this.onDownload.bind(this)
                );
              }

              //   // add overlay
              //   const overlay = document.createElement("div");
              //   overlay.classList.add("overlay");
              //   image_div.appendChild(overlay);
              //   // add the 3 span buttons in the overlay
              //   for (let i = 0; i < 3; i++) {
              //     let span = document.createElement("span");
              //     span.classList.add("size-button");
              //     span.dataset.value = i;
              //     span.dataset.tag_id = this.id;
              //     span.textContent = i + 1;
              //     if (i === parseInt(media.size)) {
              //       span.classList.add("selected");
              //     }
              //     // span.addEventListener(
              //     //   "click",
              //     //   this.onSizeButtonClick.bind(this)
              //     // );
              //     overlay.appendChild(span);
              //   }
              //   // create an edit button
              //   let editButton = document.createElement("button");
              //   editButton.classList.add("material-icons", "edit-button");
              //   editButton.textContent = "edit";
              //   // editButton.dataset.media_id = this.mediaId;
              //   overlay.appendChild(editButton);
              //   //   editButton.addEventListener(
              //   //     "click",
              //   //     this.onEditButtonClick.bind(this)
              //   //   );

              //   //create a delete button
              //   const deleteButton = document.createElement("button");
              //   deleteButton.classList.add("material-icons", "delete-button");
              //   deleteButton.textContent = "delete";
              //   overlay.appendChild(deleteButton);
              //   //   deleteButton.addEventListener(
              //   //     "click",
              //   //     this.onDeleteButtonClick.bind(this)
              //   //   );

              //   // add tags
              //   // const ul = document.createElement("ul");
              //   // ul.classList.add("tags");
              //   // const demo = ["tag1", "tag2", "tag3"];
              //   // demo.forEach((tag) => {
              //   //   const li = document.createElement("li");
              //   //   li.textContent = tag;
              //   //   ul.appendChild(li);
              //   // });
              //   // image_div.appendChild(ul);
              //   // get all tags from database
              //   // and build a select list
              const tags = await Utils.loadJson(`${Config.PATH}listTags.php`);
              //   const select = document.createElement("select");
              //   select.classList.add("tags");
              //   select.dataset.order_id = media.order_id;
              //   const option = document.createElement("option");
              //   option.textContent = "select tag";
              //   select.appendChild(option);
              //   tags.forEach((tag) => {
              //     const option = document.createElement("option");
              //     option.value = tag.tag_id;
              //     option.textContent = tag.tag_value;
              //     if (tag.tag_id == media.related_tag) {
              //       option.selected = true;
              //     }
              //     select.appendChild(option);
              //   });
              //   const selectWrapper = document.createElement("div");
              //   selectWrapper.classList.add("select-wrapper");
              //   selectWrapper.appendChild(select);
              //   image_div.appendChild(selectWrapper);

              // get all tags from the menu
              const menuElementText = document.querySelectorAll(
                "#side_menu .list li"
              );
              // create an array with all textContent of menuElementText
              const menuElementTextArray = [];
              menuElementText.forEach((element) => {
                menuElementTextArray.push(element.textContent.toLowerCase());
              });
              //   add single tag
              const ul = document.createElement("ul");
              const li = document.createElement("li");
              tags.forEach((tag) => {
                if (tag.tag_id == media.related_tag) {
                  li.textContent = tag.tag_value;
                  li.dataset.tag_id = tag.tag_id;
                  if (
                    menuElementTextArray.includes(tag.tag_value.toLowerCase())
                  ) {
                    li.classList.add("isHomepage");
                  }
                }
              });
              ul.appendChild(li);
              image_div.appendChild(ul);

              ul.addEventListener("click", this.tagClick.bind(this));
            }
            organizer.appendChild(image_div);
          }
        }
      }
      // console.log("end of loading stuff");
      resolve();
    });
  }

  async calculateAspectRatioForVideo(url) {
    // calculate aspect ratio
    let size = await Utils.getVideoSize(`${url}`);
    // calculate aspect ratio
    const aspect_ratio = size.w / size.h;
    // this.aspectRatio = aspect_ratio;
    // this.domElement.style.aspectRatio = aspect_ratio;
    return aspect_ratio;
  }

  tagClick(e) {
    this.id = e.target.dataset.tag_id;
    // check if tag is a number
    if (this.id) this.emit("click", [this.id]);
  }

  onDownload(e) {
    e.preventDefault();
    console.log(e.target.dataset.url);
    Utils.saveFile(
      e.target.dataset.url,
      e.target.dataset.realname,
      "image/jpg"
    );
  }
}
